<template>
  <div
    ref="divRef"
    class="d-flex-center search_container"
    :style="`min-height: ${heightCard}; background-image: url(${backgroundTrainSearchUrl})`"
  >
    <validation-observer ref="refFormObserver">
      <IAmOverlay :loading="loading">
        <BCard
          style="width: 70vw"
          class="search_background"
        >
          <div>
            <h4 class="text-heading-2">
              {{ $t('Train Ticket Search') }}
            </h4>
          </div>
          <BCardBody class="rounded-8px bg-gray">
            <div>
              <validation-provider
                #default="validationContext"
                :name="$t('train.bookingCode')"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-md="3"
                >
                  <template #label>
                    <div>
                      {{ $t('train.bookingCode') }}
                      <span class="text-danger">(*)</span>:
                    </div>
                  </template>
                  <b-form-input
                    v-model="bookingCode"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :formatter="removeAccentsUpperCaseFormatter"
                    maxlength="6"
                    :placeholder="$t('train.bookingCode')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <div>
              <validation-provider
                #default="validationContext"
                :name="$t('train.email')"
                rules="required|email"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-md="3"
                >
                  <template #label>
                    <div>
                      {{ $t('train.email') }}
                      <span class="text-danger">(*)</span>:
                    </div>
                  </template>
                  <b-form-input
                    v-model="email"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :formatter="trimInput"
                    :placeholder="$t('train.email')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <div>
              <validation-provider
                #default="validationContext"
                :name="$t('train.phoneNumber')"
                rules="required"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-md="3"
                >
                  <template #label>
                    <div>
                      {{ $t('train.phoneNumber') }}
                      <span class="text-danger">(*)</span>:
                    </div>
                  </template>
                  <b-form-input
                    v-model="phoneNumber"
                    v-remove-non-numeric-chars.allNumber
                    :state="getValidationState(validationContext) === false ? false : null"
                    :placeholder="$t('train.phoneNumber')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="d-flex-center justify-content-end">
              <BButton
                class="btn-gradient width-150"
                @click="onRetrieveHandle"
              >
                {{ $t('train.lookUp') }}
              </BButton>
            </div>

            <div v-if="bookingData">
              <h4 class="text-heading-3">
                {{ $t('train.detail.bookingInfo.title') }}
              </h4>
              <TrainTicketDetailBookingInfoTable
                :bookingData="bookingData"
                :showPriceTicket="true"
              />
              <h4 class="text-heading-3">
                {{ $t('train.bookerInfo') }}
              </h4>
              <BRow class="">
                <BCol
                  cols="12"
                  md="6"
                >
                  {{ $t('train.detail.contact.fullName') }}:
                  <span class="font-weight-bolder">
                    {{ bookingData.bookingPerson.fullName }}
                  </span>
                </BCol>
                <BCol
                  cols="12"
                  md="6"
                >
                  {{ $t('train.detail.contact.phoneNumber') }}:
                  <span class="font-weight-bolder">{{ bookingData.bookingPerson.phoneNumber }}</span>
                </BCol>
                <BCol
                  cols="12"
                  md="6"
                >
                  {{ $t('train.detail.contact.identifyNumber') }}:
                  <span class="font-weight-bolder">{{ bookingData.bookingPerson.identifyNumber }}</span>
                </BCol>
                <BCol
                  cols="12"
                  md="6"
                >
                  {{ $t('train.detail.contact.email') }}:
                  <span class="font-weight-bolder">{{ bookingData.bookingPerson.email }}</span>
                </BCol>
              </BRow>
            </div>
          </BCardBody>
        </BCard>
      </IAmOverlay>
    </validation-observer>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {
  BCard, BCardBody, BButton, BFormInput,
  BFormInvalidFeedback,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import { removeAccentsUpperCaseFormatter, trimInput } from '@/@core/comp-functions/forms/formatter-input'
import { apiTrain } from '@/api'
import store from '@/store'
import IAmOverlay from '@/components/IAmOverlay.vue'
import env from '@/libs/env'

import formValidation from '@core/comp-functions/forms/form-validation'

import storeModule from '@train/trainStoreModule'

import useToast from '@useToast'

import TrainTicketDetailBookingInfoTable from '../ticket-detail/components/TrainTicketDetailBookingInfoTable.vue'

export default {
  components: {
    BCard,
    BButton,
    BCardBody,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BFormGroup,
    TrainTicketDetailBookingInfoTable,
    BRow,
    BCol,
    IAmOverlay,
  },
  setup() {
    if (!store.hasModule('app-train')) {
      store.registerModule('app-train', storeModule) // NOTE: register store khi import file js này, đến hiện tại thì vẫn oke
    }
    const divRef = ref()
    const heightCard = computed(() => {
      const offsetTop = divRef.value?.offsetTop ?? 0
      return offsetTop ? `calc(100vh - ${offsetTop}px - 10px)` : '70vh'
    })
    const { toastError } = useToast()

    const bookingCode = ref()
    const email = ref()
    const phoneNumber = ref()
    const bookingData = ref('')
    const { refFormObserver, getValidationState } = formValidation()
    const loading = ref(false)
    async function onRetrieveHandle() {
      const isValid = await refFormObserver.value.validate()
      if (!isValid) return

      loading.value = true
      try {
        const payload = {
          bookingCode: bookingCode.value,
          email: email.value,
          phoneNumber: phoneNumber.value,
        }
        bookingData.value = await apiTrain.retrieveBooking(payload)
      } catch (error) {
        bookingData.value = null
        console.log({ error })
        toastError('train.msg.retrieveError')
      } finally {
        loading.value = false
      }
    }
    return {
      heightCard,
      divRef,
      refFormObserver,
      getValidationState,
      bookingCode,
      email,
      phoneNumber,
      removeAccentsUpperCaseFormatter,
      onRetrieveHandle,
      trimInput,
      bookingData,
      loading,
      backgroundTrainSearchUrl: env.backgroundTrainSearchUrl,
    }
  },
}
</script>

<style lang="scss" scoped>
.search_container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 8px;
  min-height: unset;
}

.search_background {
  background: rgba(255, 255, 255, 0.27);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  width: 1136px;
  max-width: calc(100% - 24px);
  padding: 0;
}

</style>
