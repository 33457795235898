var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "divRef",
    staticClass: "d-flex-center search_container",
    style: "min-height: ".concat(_vm.heightCard, "; background-image: url(").concat(_vm.backgroundTrainSearchUrl, ")")
  }, [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('BCard', {
    staticClass: "search_background",
    staticStyle: {
      "width": "70vw"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "text-heading-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('Train Ticket Search')) + " ")])]), _c('BCardBody', {
    staticClass: "rounded-8px bg-gray"
  }, [_c('div', [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('train.bookingCode'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-cols-sm": "4",
            "label-cols-md": "3"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('train.bookingCode')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")]), _vm._v(": ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "formatter": _vm.removeAccentsUpperCaseFormatter,
            "maxlength": "6",
            "placeholder": _vm.$t('train.bookingCode')
          },
          model: {
            value: _vm.bookingCode,
            callback: function callback($$v) {
              _vm.bookingCode = $$v;
            },
            expression: "bookingCode"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('div', [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('train.email'),
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-cols-sm": "4",
            "label-cols-md": "3"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('train.email')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")]), _vm._v(": ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "formatter": _vm.trimInput,
            "placeholder": _vm.$t('train.email')
          },
          model: {
            value: _vm.email,
            callback: function callback($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('div', [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('train.phoneNumber'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          attrs: {
            "label-cols-sm": "4",
            "label-cols-md": "3"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('train.phoneNumber')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")]), _vm._v(": ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "placeholder": _vm.$t('train.phoneNumber')
          },
          model: {
            value: _vm.phoneNumber,
            callback: function callback($$v) {
              _vm.phoneNumber = $$v;
            },
            expression: "phoneNumber"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('div', {
    staticClass: "d-flex-center justify-content-end"
  }, [_c('BButton', {
    staticClass: "btn-gradient width-150",
    on: {
      "click": _vm.onRetrieveHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.lookUp')) + " ")])], 1), _vm.bookingData ? _c('div', [_c('h4', {
    staticClass: "text-heading-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.bookingInfo.title')) + " ")]), _c('TrainTicketDetailBookingInfoTable', {
    attrs: {
      "bookingData": _vm.bookingData,
      "showPriceTicket": true
    }
  }), _c('h4', {
    staticClass: "text-heading-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('train.bookerInfo')) + " ")]), _c('BRow', {}, [_c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.contact.fullName')) + ": "), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.bookingData.bookingPerson.fullName) + " ")])]), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.contact.phoneNumber')) + ": "), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.bookingData.bookingPerson.phoneNumber))])]), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.contact.identifyNumber')) + ": "), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.bookingData.bookingPerson.identifyNumber))])]), _c('BCol', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('train.detail.contact.email')) + ": "), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.bookingData.bookingPerson.email))])])], 1)], 1) : _vm._e()])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }